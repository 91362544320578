import React, { useState, useEffect } from 'react'
import Sash1Image from '../img/sashes/Sash-1.png'
import Sash2Image from '../img/sashes/Sash-2.png'
import Sash3Image from '../img/sashes/Sash-3.png'
import Sash4Image from '../img/sashes/Sash-4.png'
import Sash5Image from '../img/sashes/Sash-5.png'

const sashArray = [Sash1Image, Sash2Image, Sash3Image, Sash4Image, Sash5Image]

const randomNumber = function(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const Sash = ({ className }) => {
  const [sashId, setSashId] = useState(0)
  useEffect(() => {
    setSashId(randomNumber(0, 4))
  }, [sashId])

  return (
    <img
      id={randomNumber(0, 4)}
      className={className}
      src={sashArray[sashId]}
    />
  )
}
