import React, { useState, useEffect } from 'react'
import shareIcon from '../img/share-apple.svg'

const ShareButton = ({
  slug,
  title
  }) => {
  const [shareOpen, setShareOpen] = useState(false)
  const [hasNavigator, setHasNavigator] = useState(false)
  const fullURL = `https://legacyquiltproject.mofad.org/quilt/${slug}`
  const shareData = {
    title: title,
    text: 'Learn more on mofad.org',
    url: fullURL
  }
  useEffect(() => {
    if(typeof navigator !== 'undefined' && typeof navigator.share !== 'undefined') {
      setHasNavigator(true)
    }
  }, [])
  useEffect(() => {
    if(shareOpen) {
      setTimeout(() => {
        setShareOpen(false)
      }, 3000)
    }
  }, [shareOpen])
  const handleShare = () => {
    if (hasNavigator) {
      navigator.share(shareData)
    } else {
      if(typeof navigator !== 'undefined' && typeof navigator.clipBoard !== 'undefined') {
        setHasNavigator(true)
      }
      navigator.clipboard.writeText(fullURL)
      setShareOpen(!shareOpen)
    }
  }
  return (
    <div>
      <button onClick={handleShare} className={`transition-opacity flex gap-2 items-center ${shareOpen && 'opacity-50'}`} disabled={shareOpen}>
        <img src={shareIcon} className="w-8 -ml-2" aria-hidden="true" loading="lazy" />
        <span>
          Share
        </span>
        </button>
      <div className={`absolute transition-opacity ${shareOpen ? 'visible opacity-100' : 'invisible opacity-0' }`}>
        <span>
          Link copied to clipboard!
        </span>
      </div>
    </div>
  )
}

export default ShareButton