import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Navbar from '../components/Navbar'
import BackupImage from '../components/BackupImage'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import { Sash } from '../components/Sashes'
import ShareButton from '../components/ShareButton'
import { GatsbyImage } from "gatsby-plugin-image"

let decode = str => {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
}

const StoryPostTemplate = ({
  categories,
  tags,
  title,
  quiltBlockFields,
  pageContext,
}) => {
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          <SEO title={`${decode(title)} - MOFAD`} image={`${quiltBlockFields.quiltImage && quiltBlockFields.quiltImage.mediaItemUrl}`} description={quiltBlockFields.description} />
          {!modal && <Navbar />}
          <section className="max-w-screen-xl mx-auto z-50">
            {modal && (
              <Link
                className="absolute top-0 right-0 p-4 text-red text-sm uppercase tracking-widest font-semibold hover:text-gray-500 transition-colors duration-100"
                to={closeTo}
                state={{
                  noScroll: true,
                }}
              >
                Close X
              </Link>
            )}
            <div
              className={`flex flex-col md:flex-row mb-32 ${
                modal ? 'pt-10' : 'p-4 md:p-10'
              } `}
            >
              <div className="md:w-1/2">
                <div className="relative w-full mb-5 aspect-square">
                  {quiltBlockFields.quiltImage ? (
                    <GatsbyImage
                      className="w-full h-full"
                      image={quiltBlockFields.quiltImage.gatsbyImage}
                      alt={quiltBlockFields.quiltImage.altText}
                    />
                  ) : (
                    <BackupImage
                      className="w-full h-full object-cover pl-12 pb-12"
                      category={categories ? categories[0].slug : 'agriculture'}
                    />
                  )}
                  {quiltBlockFields.legacy === false && (
                    <Sash className="absolute inset-0 z-1 w-full h-full" />
                  )}
                </div>
                <div className="text-sm mb-5 md:mb-0 grid gap-2 grid-cols-tax">
                  {categories && categories.length && (
                    <h4>
                      <span className="font-bold uppercase tracking-widest">
                        Category:&nbsp;
                      </span>
                    </h4>
                  )}
                  <h4 className="font-bold uppercase tracking-widest">
                    {categories && categories.map(cat => (
                      <div className="flex itemers-center">
                        <BackupImage
                          className="inline-flex w-5 h-5 object-cover rounded-full mr-2"
                          category={cat.slug}
                        />
                        <span className="inline-flex"></span>{cat.name} 
                      </div>
                    ))}
                  </h4>
                  {tags && tags.length && (
                    <h4>
                      <span className="font-bold uppercase tracking-widest">
                        Tags:&nbsp;
                      </span>
                    </h4>
                  )}
                  <h4>
                    {tags && tags.map((tag, i) => (
                      <span className="lowercase">
                        {tag.name}
                        {tags.length - 1 !== i && ','}{' '}
                      </span>
                    ))}
                  </h4>
                </div>
              </div>
              <div className="md:w-1/2 md:px-10">
                {title !== "Empty" &&
                <h1
                className="text-3xl mb-1 font-bold leading-tight"
                dangerouslySetInnerHTML={{ __html: title }}
                />
                }
                <p className="text-gray-600 mb-5">
                  {quiltBlockFields.city && <span>{quiltBlockFields.city}</span>}
                  {quiltBlockFields.state && quiltBlockFields.city && <span>, </span>}
                  {quiltBlockFields.state && <span>{quiltBlockFields.state}</span>}
                  {quiltBlockFields.state || quiltBlockFields.city ? <span> | </span> : ''}
                  {quiltBlockFields.date ? (
                    <span> {quiltBlockFields.date}</span>
                  ) : (
                    <span> {quiltBlockFields.century}</span>
                  )}
                </p>
                <div
                  className="text-lg"
                  dangerouslySetInnerHTML={{ __html: quiltBlockFields.description }}
                />
                {quiltBlockFields.legacy === true && title !== "Empty" && (
                  <div>
                    {quiltBlockFields.donor ? (
                      <p className="mt-5">
                        <span className="font-bold">Adopted By</span>{' '}
                        {quiltBlockFields.donor}
                      </p>
                    ) : (
                      <p className="mt-5">
                        This Legacy Quilt block has not yet been adopted.{' '}
                        <a
                          href="https://mofad.donation.veevartapp.com/donation/view/home/adoptablock"
                          className="underline hover:text-red"
                        >
                          Want to adopt this block?
                        </a>
                      </p>
                    )}
                  </div>
                )}
                <div
                  className="mt-5"
                  dangerouslySetInnerHTML={{ __html: quiltBlockFields.descriptionLong }}
                />
                <ShareButton slug={pageContext.slug} title={title} />
                {quiltBlockFields.legacy !== true && (
                  <p className="mt-10 text-sm text-gray-500">
                    The Museum of Food and Drink shares user submissions on an
                    “as is” basis and makes no representations or warranties of
                    any kind with respect to their contents. MOFAD assumes no
                    responsibility for errors, inaccuracies, omissions, or any
                    other inconsistencies herein.
                  </p>
                )}
              </div>
            </div>

            <div className="w-full quilt_block__control fixed left-0 bottom-0 p-5 md:p-10">
              <div className="bg-white flex justify-between opacity-75 border border-gray-500">
                {pageContext.previous ? (
                  <Link
                    className="py-5 transition-colors duration-150 hover:text-red flex items-center"
                    to={`/quilt/${pageContext.previous.slug}`}
                    state={{ modal: modal }}
                    >
                    <div className="px-4">
                      <svg className="stroke-current w-4 h-auto md:w-6" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 39L3 20.5L22 2" stroke-width="3"/>
                      </svg>
                    </div>
                    <div>
                      <span className="hidden md:block font-bold text-red md:text-gray-500">
                        Previous{' '}
                      </span>
                      <span
                        className="hidden md:block"
                        dangerouslySetInnerHTML={{ __html: pageContext.previous.title }}
                      />
                    </div>
                  </Link>
                ) : (<span> </span>)}
                {pageContext.next && (
                  <Link
                  className="py-5 transition-colors duration-150 hover:text-red flex items-center"
                    to={`/quilt/${pageContext.next.slug}`}
                    state={{ modal: modal }}
                  >
                    <div>
                      <span className="hidden md:block font-bold text-red md:text-gray-500">
                        Next{' '}
                      </span>
                      <span
                        className="hidden md:block"
                        dangerouslySetInnerHTML={{ __html: pageContext.next.title }}
                      />
                    </div>
                    <div className="px-4">
                      <svg className="stroke-current w-4 h-auto md:w-6" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L21 20.5L2 39" stroke-width="3"/>
                      </svg>
                    </div>
                  </Link>
                )}
              </div>
            </div>
            
          </section>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

const StoryPost = ({ data, location, pageContext }) => {
  const quiltBlock = data.wpQuiltBlock
  return (
    <div>
      <Helmet title={`${quiltBlock.title} | Blog`} />
      <StoryPostTemplate
        key={quiltBlock.id}
        categories={quiltBlock.categories.nodes}
        tags={quiltBlock.tags.nodes}
        title={quiltBlock.title}
        date={quiltBlock.date}
        quiltBlockFields={quiltBlock.quiltBlockFields}
        location={location}
        pageContext={pageContext}
      />
    </div>
  )
}

export default StoryPost

export const query = graphql`
  query quiltBlock($slug: String) {
    wpQuiltBlock(slug: {eq: $slug}) {
      id
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      quiltBlockFields {
        century
        date
        city
        state
        description
        descriptionLong
        donor
        gender
        locationColumn
        locationRow
        legacy
        quiltImage {
          gatsbyImage(layout: CONSTRAINED, width: 1200, height: 1200)
          altText
        }
      }
    }
  }
`
